<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>
    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                background-color="#fff"
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                @change="venueServiceChange"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.source"
                outlined
                background-color="#fff"
                placeholder="Source"
                item-text="name"
                item-value="value"
                :items="[
                  { name: 'QPortal', value: 'QPortal' },
                  { name: 'Kiosk', value: 'Kiosk' },
                  { name: 'Website', value: 'B2C' },
                ]"
                @change="getReport"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    v-model="date2Formatted"
                    outlined
                    background-color="#fff"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>

          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">Visitation Report</v-col>

                  <v-col md="4"> </v-col>

                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission(
                              $modules.reports.discount.slug
                            )
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <table class="table table-striped header-fixed">
                    <thead class="md-card-header sticky shadow-0">
                      <tr>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">Date</div>
                          </div>
                        </th>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">
                              Booking Channel
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled"
                          >
                            <div class="md-table-head-label text-center">
                              Total Booked Tickets
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">
                              Total Booked Guided Tours
                            </div>
                          </div>
                        </th>
                        <!-- <th class="md-table-head sticky">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                                text-center
                              "
                            >
                              <div class="md-table-head-label">
                                Total Garden Access
                              </div>
                            </div>
                          </th> -->
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">
                              Total Ticket Scans
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">
                              Total Not Scanned Tickets
                            </div>
                          </div>
                        </th>
                        <th class="md-table-head sticky">
                          <div
                            class="md-table-head-container md-ripple md-disabled text-center"
                          >
                            <div class="md-table-head-label">
                              Total Revenue Guided Tour
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody
                      v-if="visitationReport.length > 0"
                      :set="
                        ((total_booked_ticket = 0),
                        (total_booked_guided_tour = 0),
                        (total_booked_anonymous = 0),
                        (total_scanned_ticket = 0),
                        (total_not_scanned_ticket = 0),
                        (total_revenue_guided_tour = 0),
                        (date = ''))
                      "
                    >
                      <template>
                        <tr class="md-table-row head_bg">
                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              GRAND TOTAL
                            </div>
                          </td>
                          <td></td>
                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{ visitationReportTotal.total_bookings }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{ visitationReportTotal.total_guided_tour }}
                            </div>
                          </td>
                          <!-- <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{
                                visitationReportTotal.total_anonymous
                                  ? visitationReportTotal.total_anonymous
                                  : "0"
                              }}
                            </div>
                          </td> -->
                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{ visitationReportTotal.ticket_scans }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{
                                visitationReportTotal.total_bookings -
                                visitationReportTotal.ticket_scans
                              }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{
                                visitationReportTotal.revenue
                                  ? visitationReportTotal.revenue
                                  : 0 | toCurrency
                              }}
                            </div>
                          </td>
                        </tr>
                      </template>
                      <template v-for="(details, index) in visitationReport">
                        <tr :key="`obj_head_${index}`">
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center"
                              v-if="date != details.order_date"
                            >
                              <strong>
                                {{ details.order_date | dateformat }}</strong
                              >
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ details.source }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center"
                              :set="
                                (total_booked_ticket += Math.abs(
                                  details.total_bookings
                                ))
                              "
                            >
                              {{ details.total_bookings }}
                            </div>
                          </td>

                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center"
                              :set="
                                (total_booked_guided_tour += Math.abs(
                                  details.total_guided_tour
                                ))
                              "
                            >
                              {{
                                details.total_guided_tour
                                  ? details.total_guided_tour
                                  : 0
                              }}
                            </div>
                          </td>
                          <!-- <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center"
                              :set="
                                (total_booked_anonymous += Math.abs(
                                  details.total_anonymous
                                ))
                              "
                            >
                              {{
                                details.total_anonymous
                                  ? details.total_anonymous
                                  : 0
                              }}
                            </div>
                          </td> -->
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center"
                              :set="
                                (total_scanned_ticket += Math.abs(
                                  details.ticket_scans
                                ))
                              "
                            >
                              {{
                                details.ticket_scans ? details.ticket_scans : 0
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center"
                              :set="
                                (total_not_scanned_ticket +=
                                  Math.abs(details.total_bookings) -
                                  Math.abs(details.ticket_scans))
                              "
                            >
                              {{
                                Math.abs(details.total_bookings) -
                                Math.abs(details.ticket_scans)
                              }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            :set="(date = details.order_date)"
                          >
                            <div
                              class="md-table-cell-container text-center"
                              :set="
                                (total_revenue_guided_tour += Math.abs(
                                  details.revenue
                                ))
                              "
                            >
                              {{
                                details.revenue
                                  ? details.revenue
                                  : 0 | toCurrency
                              }}
                            </div>
                          </td>
                        </tr>
                      </template>
                      <template>
                        <tr class="md-table-row total_td_bg">
                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">TOTAL</div>
                          </td>
                          <td></td>
                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{ total_booked_ticket }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{ total_booked_guided_tour }}
                            </div>
                          </td>
                          <!-- <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{ total_booked_anonymous }}
                            </div>
                          </td> -->
                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{ total_scanned_ticket }}
                            </div>
                          </td>

                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{ total_booked_ticket - total_scanned_ticket }}
                            </div>
                          </td>

                          <td
                            class="md-table-cell-container text-center font-weight-black"
                          >
                            <div class="md-table-cell-container">
                              {{ total_revenue_guided_tour | toCurrency }}
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td :colspan="8">
                          <v-card-text class="pa-8 text-center">
                            <h3>No data found!</h3>
                          </v-card-text>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <v-pagination
                    class="mt-3 new-pagination"
                    v-model="page"
                    :length="totalPages"
                    total-visible="7"
                  ></v-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";

export default {
  components: { ExportIcon, SvgIcon, BackButton },
  data() {
    return {
      totalPages: 0,
      page: 1,
      visitationReport: [],
      visitationReportTotal: [],
      menu1: null,
      menu2: null,
      fileName: null,
      searchParams: {
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        venueServiceId: null,
      },
      containerHeight: 400,
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.getReport();
      },
    },
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.searchParams.facilityId.length == this.facilities.length) {
          this.searchParams.facilityId = [];
        } else {
          this.searchParams.facilityId = this.facilities.map((item) => item.id);
        }
      });
      setTimeout(() => {
        this.getReport();
      });
    },
    icon() {
      if (this.searchParams.facilityId.length == this.facilities)
        return "mdi-close-box";
      if (this.searchParams.facilityId.length == 0)
        return "mdi-checkbox-blank-outline";
      return "mdi-minus-box";
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },

    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }
      if (this.searchParams.customer_id) {
        url += `&customer_id=${this.searchParams.customer_id}`;
      }

      if (this.searchParams.customer_name != undefined) {
        this.fileName += "-" + this.searchParams.customer_name;
        url += `&customer_name=${this.searchParams.customer_name}`;
      }
      if (this.searchParams.source != undefined) {
        this.fileName += "-" + this.searchParams.source;
        url += `&source=${this.searchParams.source}`;
      }

      if (venueService !== null) this.fileName += "-" + venueService.name;
      if (this.fileName != null)
        this.fileName = "Visitation-Report-" + this.fileName.replace(/\s/g, "");
      else this.fileName = "Visitation-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        if (Array.isArray(key) == true) {
          return opt.id === key[0];
        } else {
          return opt.id === key;
        }
      });
      return match[0] ? match[0] : null;
    },

    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/visitation-report${url}&page=${this.page}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            const total_count = response.data.total_count;
            this.visitationReport = data["data"];
            this.visitationReportTotal = total_count;
            this.totalPages = response.data["data"].total_pages;
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },

    reportDownload(report_type) {
      let link = "";
      if (report_type == "pdf") {
        link = "visitation-report-pdf";
      } else if (report_type == "excel") {
        link = "visitation-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, report_type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },
    nameSearch(e) {
      this.searchParams.customer_id = e.customer_id;
      this.searchParams.customer_name = e.name;
      this.page = 1;
      this.getReport();
    },
    venueServiceChange() {
      this.getReport();
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edf7f8;
  z-index: 1;
  padding: 10px 4px;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
.orange-text {
  color: #ff9800 !important;
  caret-color: #ff9800 !important;
}
.total_td_bg {
  background: #edf9ff;
}
</style>
